<template>
  <div class="batteryDetail flex">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div
      class="flex flex-wrap justify-between px-8 md:px-14 w-full"
      :class="{
        'md:flex-row-reverse': $route.params.id != 'new'
      }"
    >
      <div class="w-full lg:w-1/3 lg:pl-8" v-if="$route.params.id != 'new'">
        <div
          class="w-full m-auto mb-4 py-2 px-4 relative shadow-lg rounded-lg flex justify-items-center items-center flex-col min-w-0 break-words bg-white xl:mb-5"
        >
          <QrCode
            v-if="battery.battery && battery.battery.qr_id"
            :item="battery.battery"
            :borderLess="true"
            :key="battery.battery.qr_id"
          />
        </div>
        <div class="flex flex-row flex-wrap">
          <div
            class="w-1/2"
            v-for="({ name, value, unit, type }, index) in metrics"
            :key="`${index}${name}`"
          >
            <SlideUp>
              <div
                class="relative shadow-lg rounded-lg mb-5 flex flex-col min-w-0 break-words bg-white xl:mb-5"
                :class="{
                  'mr-2': index % 2 == 0,
                  'ml-2': index % 2 != 0
                }"
              >
                <div class="flex-auto p-4">
                  <div class="flex flex-wrap">
                    <div
                      class="relative w-full pr-4 max-w-full flex-grow flex-1"
                    >
                      <h5 class="text-blueGray-400 uppercase font-bold text-xs">
                        {{ $t(name) }}
                      </h5>
                      <p
                        class="font-bold text-4xl text-blueGray-700 text-center"
                        v-if="value"
                      >
                        <!-- <span
                            class="w-5 h-5"
                            v-if="type == 'percent'"
                            :class="`text-${getPercentTagColor(
                              value
                            )}-100 bg-${getPercentTagColor(value)}-500`"
                          >
                          </span> -->
                        <span v-if="type == 'time'">
                          {{ getTimeNoUnit(value) }}
                        </span>
                        <span v-else>
                          {{ value }}
                        </span>
                      </p>
                      <p
                        class="font-bold text-4xl text-blueGray-700 text-center"
                        v-else
                      >
                        {{ $t("-") }}
                      </p>
                      <p
                        v-if="value"
                        class="text-sm text-blueGray-600 text-right"
                      >
                        <!-- <span
                            class="w-5 h-5"
                            v-if="type == 'percent'"
                            :class="`text-${getPercentTagColor(
                              value
                            )}-100 bg-${getPercentTagColor(value)}-500`"
                          >
                          </span> -->
                        <span v-if="type == 'time'">
                          {{ getTimeUnit(value) }}
                        </span>
                        <span v-else>
                          {{ unit }}
                        </span>
                      </p>
                    </div>
                    <div class="relative w-auto pl-4 flex-initial"></div>
                  </div>
                </div>
              </div>
            </SlideUp>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-2/3">
        <SlideUp>
          <Form :form="formData" formName="battery detail" />
        </SlideUp>
        <SlideUp>
          <div
            class="rounded-lg bg-white mb-0 px-6 shadow-lg"
            v-if="charges.length > 0"
          >
            <h2
              class="text-blueGray-400 uppercase font-bold text-xs pb-3 flex justify-between items-center pt-6"
            >
              {{ $t("charges") }}
            </h2>
            <div class="overflow-y-auto h-96">
              <div
                v-for="(charge, i) in charges"
                :key="charge.id"
                class="py-2"
                :class="{ 'border-t': i != 0 }"
              >
                <div class="flex justify-between items-center">
                  <div>
                    <h3>{{ $t("charge") }} #{{ i + 1 }}</h3>
                    <div class="text-semibold ml-2 text-blueGray-500 text-xs">
                      {{ $t("notes") }}: {{ charge.notes }}
                    </div>
                  </div>
                  <div>{{ formatTime(charge.inserted_at) }}</div>
                </div>
              </div>
            </div>
          </div>
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import SlideUp from "@/components/transitions/SlideUp.vue";
import Form from "@/components/Form.vue";
import QrCode from "@/components/QrCode.vue";
import { mapState } from "vuex";
import formatting from "../mixins/formatting";
import { bindData } from "../helpers/form";
export default {
  name: "BatteryDetail",
  components: {
    SlideUp,
    Form,
    QrCode
  },
  mixins: [formatting],
  computed: {
    ...mapState("batteries", ["battery"]),
    charges() {
      return this.battery?.battery?.battery_charges
        ? this.battery?.battery?.battery_charges
        : [];
    },
    qrName() {
      const id = this.battery?.battery?.id;
      return id ? `#${id}` : undefined;
    },
    metrics() {
      return [
        {
          name: "Total charges",
          value: this.battery?.number_of_charges,
          unit: "charges"
        },
        {
          name: "Life Time",
          value: this.battery?.life_time,
          type: "percent",
          unit: "%"
        }
      ];
    },
    bikes() {
      return this.$store.state.bikes.bikes;
    },
    hubs() {
      return this.$store.state.hubs;
    },
    suppliers() {
      return this.$store.state.suppliers;
    }
  },
  data() {
    const { hubs } = this.$store.state.hubs;
    const { suppliers } = this.$store.state.suppliers;
    const { bikes } = this.$store.state.bikes;
    return {
      formData: [
        {
          section: "Battery Information",
          dispatch:
            this.$route.params.id != "new"
              ? "batteries/updateBattery"
              : "batteries/createBattery",
          fields: [
            { key: "id", name: "id", type: "hidden", value: "" },
            {
              name: "name",
              key: "name",
              value: "",
              type: "text"
            },
            {
              name: "hub",
              key: "hub_id",
              value: "",
              type: "select",
              options: hubs,
              optionLabelKey: "name"
            },
            {
              name: "box",
              key: "bike_id",
              value: "",
              type: "select",
              options: bikes,
              optionLabelKey: "name"
            },
            {
              name: "supplier",
              key: "supplier_id",
              value: "",
              type: "select",
              options: suppliers,
              optionLabelKey: "name"
            },
            { key: "notes", value: "", name: "notes", type: "textarea" }
          ]
        }
      ]
    };
  },
  watch: {
    battery() {
      bindData(this.formData, this.battery.battery);
    },
    bikes() {
      this.formData[0].fields[3].options = this.bikes;
    },
    hubs() {
      this.formData[0].fields[2].options = this.hubs;
    },
    suppliers() {
      this.formData[0].fields[4].options = this.suppliers;
    }
  },
  mounted() {
    if (this.$route.params.id != "new") {
      this.$store.dispatch("batteries/getBattery", this.$route.params.id);
    } else {
      this.$store.commit("batteries/RESET_BATTERIES");
    }
  }
};
</script>
