var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"batteryDetail flex"},[_c('div',{staticClass:"fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12"}),_c('div',{staticClass:"flex flex-wrap justify-between px-8 md:px-14 w-full",class:{
      'md:flex-row-reverse': _vm.$route.params.id != 'new'
    }},[(_vm.$route.params.id != 'new')?_c('div',{staticClass:"w-full lg:w-1/3 lg:pl-8"},[_c('div',{staticClass:"w-full m-auto mb-4 py-2 px-4 relative shadow-lg rounded-lg flex justify-items-center items-center flex-col min-w-0 break-words bg-white xl:mb-5"},[(_vm.battery.battery && _vm.battery.battery.qr_id)?_c('QrCode',{key:_vm.battery.battery.qr_id,attrs:{"item":_vm.battery.battery,"borderLess":true}}):_vm._e()],1),_c('div',{staticClass:"flex flex-row flex-wrap"},_vm._l((_vm.metrics),function(ref,index){
    var name = ref.name;
    var value = ref.value;
    var unit = ref.unit;
    var type = ref.type;
return _c('div',{key:("" + index + name),staticClass:"w-1/2"},[_c('SlideUp',[_c('div',{staticClass:"relative shadow-lg rounded-lg mb-5 flex flex-col min-w-0 break-words bg-white xl:mb-5",class:{
                'mr-2': index % 2 == 0,
                'ml-2': index % 2 != 0
              }},[_c('div',{staticClass:"flex-auto p-4"},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"relative w-full pr-4 max-w-full flex-grow flex-1"},[_c('h5',{staticClass:"text-blueGray-400 uppercase font-bold text-xs"},[_vm._v(" "+_vm._s(_vm.$t(name))+" ")]),(value)?_c('p',{staticClass:"font-bold text-4xl text-blueGray-700 text-center"},[(type == 'time')?_c('span',[_vm._v(" "+_vm._s(_vm.getTimeNoUnit(value))+" ")]):_c('span',[_vm._v(" "+_vm._s(value)+" ")])]):_c('p',{staticClass:"font-bold text-4xl text-blueGray-700 text-center"},[_vm._v(" "+_vm._s(_vm.$t("-"))+" ")]),(value)?_c('p',{staticClass:"text-sm text-blueGray-600 text-right"},[(type == 'time')?_c('span',[_vm._v(" "+_vm._s(_vm.getTimeUnit(value))+" ")]):_c('span',[_vm._v(" "+_vm._s(unit)+" ")])]):_vm._e()]),_c('div',{staticClass:"relative w-auto pl-4 flex-initial"})])])])])],1)}),0)]):_vm._e(),_c('div',{staticClass:"w-full lg:w-2/3"},[_c('SlideUp',[_c('Form',{attrs:{"form":_vm.formData,"formName":"battery detail"}})],1),_c('SlideUp',[(_vm.charges.length > 0)?_c('div',{staticClass:"rounded-lg bg-white mb-0 px-6 shadow-lg"},[_c('h2',{staticClass:"text-blueGray-400 uppercase font-bold text-xs pb-3 flex justify-between items-center pt-6"},[_vm._v(" "+_vm._s(_vm.$t("charges"))+" ")]),_c('div',{staticClass:"overflow-y-auto h-96"},_vm._l((_vm.charges),function(charge,i){return _c('div',{key:charge.id,staticClass:"py-2",class:{ 'border-t': i != 0 }},[_c('div',{staticClass:"flex justify-between items-center"},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("charge"))+" #"+_vm._s(i + 1))]),_c('div',{staticClass:"text-semibold ml-2 text-blueGray-500 text-xs"},[_vm._v(" "+_vm._s(_vm.$t("notes"))+": "+_vm._s(charge.notes)+" ")])]),_c('div',[_vm._v(_vm._s(_vm.formatTime(charge.inserted_at)))])])])}),0)]):_vm._e()])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }